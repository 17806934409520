import "./main.css";
import { Elm } from "./Main.elm";
import registerServiceWorker from "./registerServiceWorker";
import FixedMidashi from "./fixed_midashi";
import DragPorts from "./DragPorts";

//localStorage.removeItem('userInfo');
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

const bindAddress = process.env.ELM_APP_BIND_ADDRESS || "http://0.0.0.0";
const apiDestIp = process.env.ELM_APP_API_DEST_IP || "http://localhost";
const apiDestPort = parseInt(process.env.ELM_APP_API_DEST_PORT, 10) || 5001;

console.log(apiDestIp, apiDestPort);

const app = Elm.Main.init({
    node: document.getElementById("root"),
    flags: { ...userInfo, bindAddress, apiDestIp, apiDestPort },
});

// tokenの書き出し
app.ports.storeUserInfo.subscribe((userInfo) => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    app.ports.login.send(userInfo);
});

// tokenの削除
app.ports.removeUserInfo.subscribe(() => {
    localStorage.removeItem("userInfo");
    app.ports.logout.send(null);
});

app.ports.createFixedMidashi.subscribe(() => {
    setTimeout(() => {
        FixedMidashi.create();
    }, 100);
});

const dragPorts = DragPorts();
dragPorts.setup(app);

registerServiceWorker();
